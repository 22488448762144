import React, { createContext, useState, useEffect, useContext } from 'react';

// Create a new context
const SessionContext = createContext();

// Create a provider component
export const SessionProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  // Load user from session storage on component mount
  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    //console.log(storedUser);
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // Update session storage whenever user changes
  useEffect(() => {
    if (user) {
      sessionStorage.setItem('user', JSON.stringify(user));
    } else {
      sessionStorage.removeItem('user');
    }
  }, [user]);

  const signIn = (userData) => {
    setUser(userData);
  };

  const signOut = () => {
    setUser(null);
  };

  return (
    <SessionContext.Provider value={{ user, signIn, signOut }}>
      {children}
    </SessionContext.Provider>
  );
};

// Custom hook to use session context
export const useSession = () => useContext(SessionContext);
