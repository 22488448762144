import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import moment from "moment";
import { credientials } from './credientials';
import axios from 'axios';

function Cancellationbooking() {
    const [loading, setLoading]=useState(false);
    const { accommodationid, bookingid, pincode, agent } = useParams();
    //console.log(agent)
    var agentData=credientials.find(c=>c.agent.toLowerCase()==agent.toLowerCase());
    //console.log(agentData);

    const [Data, setData] = useState({
        bookingData: '',
        bookingDetail: '',
        TotalRoom: 0,
        cancelReason: '',
        loading: false,
        payment_details:""
    });
    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        fetch('/api/reservationcancellation', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accoId: accommodationid, bookingid: bookingid, pincode: pincode,userId: agentData.userId,
                password: agentData.password }),
        }).then((cancelData) => {
            console.log(cancelData);
            if (!cancelData.ok) {
                throw Error('could not fetch the data for that resorce')
            }
            return cancelData.json()
        }).then(async(cancelDataJson) => {
            console.log(cancelDataJson);
            if (cancelDataJson.ReservationDetails) {
                setData({ ...Data, bookingData: { data: cancelDataJson.ReservationDetails.Success[0].Result[0], status: 3 } })
            }
            if(agent.toLowerCase()=="hbl" && Data.payment_details){
                const refundPaymnet= await axios.post('/api/refund_payment', Data.payment_details);
                console.log("---------------REFUND PROCESS--------");
                console.log(refundPaymnet.data);
            }
            setLoading(false);

        }).catch(err => {
            console.log(err)
        })
    };


useEffect(() => {
    
if(agentData){
    
    fetch('/api/getreservationdetails', {
        method: "POST",
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accoId: accommodationid, bookingid: bookingid, pincode: pincode , userId: agentData.userId,
            password: agentData.password}),
    }).then(Rdata => {
        if (!Rdata.ok) {
            throw Error('could not fetch the data for that resorce')
        }
        return Rdata.json()
    }).then(async (bookingdetails) => {
        var total = 0;
        for (let i = 0; i < bookingdetails.getMultipleBookingsDetail.Success[0].Result[0]['RoomDetails'].length; i++) {
            total += Number(bookingdetails.getMultipleBookingsDetail.Success[0].Result[0]['RoomDetails'][i]['RoomQuantity']);
        }
        
        const payment_details= agent.toLowerCase()=="hbl"? await axios.post("/api/get_payment", { booking_id:bookingid}):'';
       // console.log(payment_details.data)
        setData({ ...Data,payment_details:payment_details.data?payment_details.data.Collection.Rows[0]:'', bookingDetail: bookingdetails.getMultipleBookingsDetail.Success[0].Result[0], loading: true, TotalRoom: total })

    }).catch(err => console.log(err));

}else{
    alert("something went wrong. please contact support.")
}
       
    }, []);

    return (
        Data.loading ? <div className="container">
            <div className="row">
                {/* {console.log(Data)} */}
                <div className="col-lg-8 col-md-12 col-sm-12 mx-auto pb-5">
                    <div className="container">
                        <div className="row">
                            {Data.bookingData['status'] == 3 ?

                                <div className="col-12 mt-4 p-0">
                                    <div className="alert alert-danger" role="alert" style={{ backgroundColor: "#EF4E22 !important", color: "white" }}>
                                        <strong>Your booking is cancelled!</strong>
                                    </div>
                                </div> :
                                Data.bookingDetail.ArrivalDate[0] <= moment().format('YYYY-MM-DD') ?
                                    <div className="col-12 mt-4 p-0">
                                        <div className="alert alert-info" role="alert" style={{ backgroundColor: "#EF4E22 !important", color: "white" }}>
                                            <strong>Your booking can't be cancelled.</strong>
                                        </div>
                                    </div> :
                                    <form name="cancal" id="cancal" style={{ width: "100%" }} onSubmit={handleSubmit}>
                                        <input type="hidden" name="_token" value="saI1sbOSDFt1BLfYUtsEqNq978lqmI9Tf6dhrO4n" />
                                        <div className="form-group roomData">
                                            <h1 className="varela-round ft-18 text-dark mt-4 mb-0">Cancellation Reason:</h1>
                                            <div className="right-div-confrim"><textarea name="canMsg" id="canMsg" className="form-control" style={{ height: "90px" }} value={Data.cancelReason} onChange={(e) => { setData({ ...Data, cancelReason: e.target.value }) }}></textarea>
                                                <div style={{ marginTop: "6px" }}>
                                                    <button name="cancel-btn" id="cancal-btn" className="btn btn-primary px-3 py-2" >
                                                        {loading?
                                                          <div class="spinner-border" role="status" >
                                                          <span class="visually-hidden">Loading...</span>
                                                          </div>
                                                        :null}
                                                  &nbsp;&nbsp;Submit</button>
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                    </form>}

                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 p-0">
                                <h1 className="varela-round ft-18 text-dark">Booking Summary</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 form-bg border shadow-sm mt-2 bg-light">
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16 text-capitalize">Booking
                                                        Number</p>
                                                </div>
                                                <div className="col-8">
                                                    <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-13">
                                                        {Data.bookingDetail['BookingID']}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16 text-capitalize">PIN Code:</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p
                                                        className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15 text-capitalize">
                                                        {Data.bookingDetail['PinCode']}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16 text-capitalize">Booker First Name:</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15 text-capitalize">
                                                        {Data.bookingDetail['BookerFisrtName']}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16 text-capitalize">Booker Last Name:</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15 text-capitalize">
                                                        {Data.bookingDetail['BookerLastName'][0] !== "undefined" ?Data.bookingDetail['BookerLastName'][0]:''}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16 text-capitalize">Email Address:</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15 ">
                                                        {Data.bookingDetail['BookerEmail']}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16 text-capitalize">Booking Status:</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p
                                                        className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15 text-capitalize" style={{ fontWeight: "bold" }}>
                                                        {Data.bookingDetail['BookingStatus']}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12 ">
                                                    <p className="text-black  ft-16 text-capitalize">Booking
                                                        Details</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15">{Data.TotalRoom}
                                                        &nbsp;Room(s), {Data.bookingDetail['NoOfNights']} night(s)</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12 ">
                                                    <p className="text-black  ft-16 ">Check-in</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p className="border-left text-d-gray w-100 py-1 mb-2 pl-3  ft-15">
                                                        {Data.bookingDetail['ArrivalDate']}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="border-bottom ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16">Check-out</p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p className="border-left text-d-gray w-100 py-1 mb-2 pl-3  ft-15">
                                                        {Data.bookingDetail['DepartureDate']}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12 mt-3">
                                        <div className="ml-5 mr-5">
                                            <div className="row">
                                                <div className="col-lg-4 col-md-4 col-12">
                                                    <p className="text-black  ft-16 mt-1"><b>Total Price:</b></p>
                                                </div>
                                                <div className="col-lg-8 col-md-8 col-12">
                                                    <p className="text-orange w-100 py-1 mb-2 pl-3  ft-16"><b>
                                                        {Data.bookingDetail['BassCurrencyCode']}&nbsp;{Data.bookingDetail['TotalPrice']}
                                                    </b></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-6 col-md-6 p-0">
                                <h1 className="varela-round ft-18 text-dark mt-4 mb-0">Room Details</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12 col-md-12 form-bg mt-2 bg-light">
                                <div className="row">
                                    {Data.bookingDetail['RoomDetails'] ?
                                        Data.bookingDetail['RoomDetails'].map((room, index) => {
                                            return (

                                                <div className="col-lg-12 col-md-12 form-bg border" key={index}>
                                                    <div className="row">
                                                        <div className="col-lg-12 mt-3">
                                                            <div className="border-bottom ml-5 mr-5">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-12 ">
                                                                        <p className="text-black text-bold ft-16">{index + 1} Room(s)</p>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <p className="border-left text-d-gray w-100 py-1 mb-2 pl-3  ft-15">
                                                                            {room['RoomName']} - {room['RatePlanName']} </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12 mt-3">
                                                            <div className="border-bottom ml-5 mr-5">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-12">
                                                                        <p className="text-black  ft-16 text-capitalize">Guest Name</p>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-12">
                                                                        <p
                                                                            className="border-left text-d-gray w-100 py-1 mb-2 pl-3  ft-15 text-capitalize">
                                                                            {room['GuestName']}</p>
                                                                        <p className="text-d-gray w-100 py-1 mb-2 pl-3  ft-15">for max. {room['GuestQty']}
                                                                            person</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-12 mt-3">
                                                            <div className="ml-5 mr-5 border-bottom">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-12 mb-0">
                                                                        <p className="text-black  ft-16 text-capitalize">Room Price:
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-12">
                                                                        <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15">
                                                                            <b>{Data.bookingDetail['BassCurrencyCode']} &nbsp; {room['RoomTotalPrice']}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mt-3">
                                                            <div className="ml-5 mr-5 border-bottom">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-12 mb-0">
                                                                        <p className="text-black  ft-16 text-capitalize">Cancellation Policy:
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-12">
                                                                        <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15">
                                                                            <b>{room['PolicyCancellation']}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mt-3">
                                                            <div className="ml-5 mr-5">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-12 mb-0">
                                                                        <p className="text-black  ft-16 text-capitalize">NoShow Policy:
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-12">
                                                                        <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15">
                                                                            <b>{room['NoShowPolicy']}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-12 mt-3">
                                                            <div className="ml-5 mr-5">
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-md-4 col-12 mb-0">
                                                                        <p className="text-black  ft-16 text-capitalize">Booking Policy:
                                                                        </p>
                                                                    </div>
                                                                    <div className="col-lg-8 col-md-8 col-12">
                                                                        <p className="border-left text-orange w-100 py-1 mb-2 pl-3  ft-15">
                                                                            <b>{room['BookingPolicy']}</b></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                        : ''
                                    }

                                </div>
                                <div className="mt-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> : ''

    )
}

export default Cancellationbooking
