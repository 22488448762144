import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import { Link } from "react-router-dom";

import UpdatePassword from "./UpdatePassword.jsx";
import { useSession } from "../../SessionProvider";
import {
  logo_url,
  white_logo_url,
} from "../BasicComponents/BasicFunctionScript";

// import Button from "../BasicComponents/Button";
export default function Topbar(props) {
  const { user, signOut } = useSession();
  const [isOpen, setOpen] = useState(false);
  //console.log(user);
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <section className={`${props.page == "home" ? "home-bar" : "orange-bar"}`}>
      {isModalOpen && <UpdatePassword setIsModalOpen={setIsModalOpen} />}
      <div className="container-2 py-1">
        <div className="display-flex w-100 j-between item-center">
          <div>
            <Link to="/" className="roomph-logo">
              <img
                className="roomphLogoH"
                src={props.page !== "home" ? white_logo_url : logo_url}
                alt="logo"
              />
            </Link>
          </div>
          <div className="">
            <div>
              {user ? (
                <>
                  <span className="f-15">
                    Welcome, {user.name}!{" "}
                    <IoIosArrowDown
                      className="pointer"
                      onClick={() => setOpen(!isOpen)}
                    />
                  </span>
                  {/* <Link onClick={() => signOut()} to="/" className="ml-2">
                    <Button
                      childern="Sign Out"
                      type="button"
                      buttonStyle={
                        props.page !== "home" ? "btn-secondary" : "btn-primary"
                      }
                    ></Button>
                  </Link>
                  <Link to="/booking">
                    <Button
                      childern="My Bookings"
                      type="button"
                      buttonStyle={
                        props.page !== "home" ? "btn-secondary" : "btn-primary"
                      }
                    ></Button>
                  </Link> */}
                  {isOpen && user ? (
                    <ul class="dropdown-contentTopbar">
                      <li class="nav-item-drop">
                        <Link to="/hotels_list">My Hotels</Link>
                      </li>
                      <hr class="my-2" />

                      <li class="nav-item-drop">
                        <Link to="/booking">Bookings</Link>
                      </li>
                      <hr class="my-2" />

                      <li class="nav-item-drop">
                        <Link
                          to="#"
                          onClick={() => {
                            console.log("in");
                            setIsModalOpen(true);
                          }}
                        >
                          Update Password
                        </Link>
                      </li>
                      <hr class="my-2" />
                      <li class="nav-item-drop">
                        <Link onClick={() => signOut()} to="/">
                          Logout
                        </Link>
                      </li>
                    </ul>
                  ) : null}
                </>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
