import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { isMobile } from "react-device-detect";
import { FaCheck } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

import { ReactComponent as Stars } from "../../Assests/Asset100.svg";
import { page_view } from "../../RoomphGTMTracking";
import { useSession } from "../../SessionProvider";
import history from ".././history";
import {
  createBooking,
  initiateMyCheckout,
  fetchHotelDetail,
  percentageCalculator,
} from "../BasicComponents/BasicFunctionScript";
import Footer from "../footer/Footer";
import Topbar from "../Topbar/Topbar";

import "aos/dist/aos.css";
import "react-loading-skeleton/dist/skeleton.css";

function CustomerInformation() {
  const { user } = useSession();
  const location = useLocation();
  const {
    hotelParams,
    roomId,
    Total,
    extraids,
    ratePlanId,
    ratesbydate,
    roomqty,
    roomName,
  } = location.state;
  const discount = location.state.discount;
  console.log("discount=>", discount);
  // console.log(discount[0].accommodation_advancePercentage);

  const [hotelDeatails, sethotelDeatails] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [bookingDEtail, setbookingDEtail] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [Tax, setTax] = useState([]);
  const [TaxAmount, setTaxAmount] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [finalPrice, setfinalPrice] = useState(Total);

  // const handleOnChange = (e) => {
  //   setIsChecked(e.target.checked);
  // };

  const [contactInfo, setContactInfo] = useState({
    fullname: user && user.name,
    phone: user && user.phone,
    city: user && user.city,
    email: user && user.email,
    promo: "",
  });
  useEffect(() => {
    page_view("Booking");
  }, []);

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name == "tapCode") {
      if (
        event.target.value.toLowerCase() == contactInfo.code.toLocaleLowerCase()
      ) {
        setIsChecked(true);
      }
    }
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };

  const FinalStepTowardsBooking = async () => {
    setspinner(true);

    try {
      var CalculatedPrice = Total;
      var taxFinal = Math.round(finalPrice - Total);
      var finalForeePrice = Number(CalculatedPrice) + Number(taxFinal);

      var comments = discount.length
        ? `This is a Special Rate Booking created against TAP Agent  ${user.name}. ${discount[0].rate_plans[0].accommodation_advancePercentage}% Advance Payment for this Booking was charged using Foree.`
        : "";
     // console.log(ratesbydate);
      let isdocount = discount.length ? true : false;

      let isadvanceCharge = isdocount
        ? discount[0].rate_plans[0].accommodation_advancePercentage
        : null;
      console.log(discount[0].rate_plans[0].accommodation_advancePercentage);
      if (isadvanceCharge !== 0 && isadvanceCharge !== null) {
        finalForeePrice = percentageCalculator(
          finalForeePrice,
          isadvanceCharge
        );
      }
      const BookingData = await createBooking(
        comments,
        contactInfo,
        hotelParams,
        taxFinal,
        roomId,
        ratePlanId,
        extraids,
        hotelParams.adults,
        Number(Total),
        ratesbydate,
        roomqty,
        isadvanceCharge == 0 ? true : false,
        user.id
      );

      if (isChecked || isadvanceCharge == 0) {
        //console.log(BookingData);
        setbookingDEtail(BookingData);
        setspinner(false);
        history.push({
          pathname: "/thankyou",
          state: {
            hotelParams: hotelParams,
            Tax: Tax,
            roomqty: roomqty.split(",").length,
            contactInfo: contactInfo,
            bookingDEtail: BookingData,
            hotelDeatails: hotelDeatails,
            Total: Total,
            TaxAmount: TaxAmount,
            ratePlanId: ratePlanId,
            finalPrice: finalPrice,
            discount: discount,
          },
        });
      } else {
        setbookingDEtail(BookingData);
        setspinner(false);
        initiateMyCheckout(
          hotelParams.id,
          BookingData,
          finalForeePrice,
          contactInfo.email,
          contactInfo.phone
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    FinalStepTowardsBooking();
  };

  const fetchDetails = async () => {
    const detail = await fetchHotelDetail(hotelParams);
    //console.log(detail);
    if (detail) {
      sethotelDeatails(detail);
      if (detail.hoteltaxes) {
        if (detail.hoteltaxes[0].length !== 0) {
          var finalPriceCal = 0;
          detail.hoteltaxes[0].Tax.map((tax, index) => {
            //2 for not count tax 1 for count tax
            if (tax.$.TaxType !== "1") {
              finalPriceCal = Number(
                finalPriceCal + Number((tax.$.TaxPercent * Total) / 100)
              );

              //setTaxname(`${Number(tax.$.TaxPercent).toLocaleString()}% ${tax.$.TaxName}`);
              console.log(detail.hoteltaxes[0].Tax);
            }
            if (index == detail.hoteltaxes[0].Tax.length - 1) {
              setTax(detail.hoteltaxes[0].Tax);
              setTaxAmount(finalPriceCal);
              setfinalPrice(Number(finalPrice) + Number(finalPriceCal));
              setLoading(true);
            }
          });
        }
      } else {
        setLoading(true);
      }
    }
  };

  useEffect(() => {
    //console.log(discount)
    fetchDetails();
  }, []);
  return (
    <>
      <Topbar color="#FF3D00" page="check-out" />

      <section
        className="box-shadow-3"
        style={{
          backgroundColor: "white",
          borderRadius: "0px 0px 15px 15px",
          borderBottom: "1px solid rgb(205, 206, 206)",
        }}
      >
        <div className="container-3 py-2">
          <div className="px-4 d-flex align-items-center justify-content-center py-0 m-0">
            <div className="step1">1</div>
            <hr
              style={{ width: "89%", border: "1px solid var(--hover-color)" }}
            />
            <div className="step2">
              <FaCheck />
            </div>
            {/* <button className="step2"><SiNike /><p className="CI">Booking Confirmed</p></button> */}
          </div>
          <div className="d-flex m-0 p-0">
            <div>
              <p
                className="f-15 line-1 text-center mt-2"
                style={{ color: "var(--hover-color)" }}
              >
                Customer Information
              </p>
            </div>
            <div style={{ width: "95%" }}></div>
            <div>
              <p
                className="f-15 line-1 text-center mt-2"
                style={{ color: "var(--hover-color)" }}
              >
                Booking Confirmed
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 mb-5 infor_hotel_checkout">
        <div className={isMobile ? "container-2" : "container"}>
          {/* <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 p-0">
              <div className="signUpCapsule box-shadow-2"><u>Sign up</u> and get 20% off on your first booking.</div>
            </div>
          </div> */}
          <div className="row mt-5">
            <div
              className="col-lg-6 col-md-6 col-12"
              style={{
                backgroundColor: "#f6f6f6",
                borderRadius: isMobile ? "13px" : "15px 0px 0px 50px",
                border: "1px solid rgb(203, 203, 203)",
                height: isMobile ? "auto" : "450px",
                boxShadow: isMobile ? "" : "rgb(205 206 206) -1px 1px 1px 1px",
              }}
            >
              <div className="row py-lg-3 px-lg-3 px-1 py-3">
                <div className="col-lg-7 col-md-7 col-7">
                  {/* <button className="btn btn-primary BButton" type="button"> Best Seller </button> */}
                  {Loading ? (
                    <h6 className="mt-2 sub-heading">
                      {hotelDeatails.AccommodationName}
                    </h6>
                  ) : (
                    <Skeleton className="mt-2 main-heading"></Skeleton>
                  )}
                  {Loading ? (
                    <p className="d-flex align-item-center">
                      {" "}
                      {Array.apply(null, {
                        length: hotelDeatails.Rating
                          ? Number(hotelDeatails.Rating)
                          : 0,
                      })
                        .map(Number.call, Number)
                        .map((item) => {
                          return <Stars className="imgWidr" key={item} />;
                        })}
                    </p>
                  ) : (
                    <Skeleton className="d-flex align-item-center"></Skeleton>
                  )}

                  <p className="mt-1 f-8 hotel-checkout-address">
                    {Loading ? hotelDeatails.Address : <Skeleton />}
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  {Loading ? (
                    <img
                      className="hotelImgCI"
                      style={{ borderRadius: "15px" }}
                      src={
                        hotelDeatails.hotelImages[0].Image_URLs[0].$.Image_URL
                      }
                      alt=""
                    />
                  ) : (
                    <Skeleton
                      className="hotelImgCI"
                      style={{ borderRadius: "15px" }}
                    />
                  )}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 border-bottom pb-2 pt-3">
                  {/* {()=>{StandardDateFn(hotelParams.checkin)}} */}
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      Check-in:
                    </span>
                    <span className="col-lg-5 col-6 text-right">
                      {moment(hotelParams.checkin).format("Do MMM YYYY, dddd")}
                    </span>
                  </p>
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      Check-out:
                    </span>
                    <span className="col-lg-5 col-6 text-right">
                      {moment(hotelParams.checkout).format("Do MMM YYYY, dddd")}
                    </span>
                  </p>

                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">Stay:</span>
                    <span className="col-lg-5 col-6 text-right">
                      {hotelParams.nights} Night
                      {hotelParams.nights > 1 ? "s" : ""},{" "}
                      {roomqty.split(",").length} Room
                      {roomqty.split(",").length > 1 ? "s" : ""},{" "}
                      {hotelParams.adults} Adult
                      {hotelParams.adults > 1 ? "s" : ""}
                    </span>
                  </p>
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">Room:</span>
                    <span className="col-lg-5 col-6 text-right">
                      {roomName}
                    </span>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      {roomqty ? roomqty.split(",").length : hotelParams.rooms}{" "}
                      Room ({hotelParams.nights ? hotelParams.nights : ""} Night
                      {hotelParams.nights > 1 ? "s" : ""}):
                    </span>
                    <span className="col-lg-5 col-6 text-right">
                      <span>
                        {Loading
                          ? hotelDeatails["CurrencyCode"][0] == "USD"
                            ? "PKR"
                            : hotelDeatails["CurrencyCode"][0]
                          : null}{" "}
                        {Math.round(Total).toLocaleString()}
                        {/* {percentagePriceCalculator(
                            Total,
                            discount.discount_percentage
                          )} */}
                      </span>
                    </span>
                  </p>

                  {Loading ? (
                    Tax.map((tax, index) => {
                      console.log(tax);
                      let taxAmounutfrom = Total;

                      return tax.$.TaxType !== "1" ? (
                        <p className="row f-15" key={index}>
                          <span className="col-lg-4 col-6 fouth-heading">
                            Tax ({Number(tax.$.TaxPercent).toLocaleString()}%{" "}
                            {tax.$.TaxName}):
                          </span>
                          <span className="col-lg-5 col-6 text-right">
                            {Loading
                              ? hotelDeatails["CurrencyCode"][0] == "USD"
                                ? "PKR"
                                : hotelDeatails["CurrencyCode"][0]
                              : null}{" "}
                            {Math.round(
                              (tax.$.TaxPercent * taxAmounutfrom) / 100
                            ).toLocaleString()}
                          </span>
                        </p>
                      ) : null;
                    })
                  ) : (
                    <Skeleton></Skeleton>
                  )}
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">Total:</span>
                    <span className="col-lg-5 col-6 text-right ">
                      {/* {discount ? ( */}
                      <span>
                        {Loading
                          ? hotelDeatails["CurrencyCode"][0] == "USD"
                            ? "PKR"
                            : hotelDeatails["CurrencyCode"][0]
                          : null}{" "}
                        {Math.round(Total + TaxAmount).toLocaleString()}
                      </span>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              style={{ marginTop: isMobile ? "20px" : "" }}
            >
              <form
                onSubmit={handleSubmit}
                className="row py-lg-3 px-lg-3 px-1 py-3 ml-lg-1 box-shadow-3"
                style={{
                  borderRadius: isMobile
                    ? "15px 15px 50px 50px"
                    : "0px 15px 50px 0px",
                  border: "1px solid rgb(203, 203, 203)",
                  height: isMobile ? "auto" : "450px",
                }}
              >
                <h6 className="mb-3 f-18 fouth-heading">
                  Let us know who you are
                </h6>
                {/* formarrray */}
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="exampleInputEmail1"
                    placeholder="Full name *"
                    aria-describedby="emailHelp"
                    value={contactInfo.fullname}
                    name="fullname"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    placeholder="Email *"
                    id="exampleInputPassword1"
                    value={contactInfo.email}
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <input type="email" class="form-control form-control-sm" placeholder="Retype email *" id="exampleInputPassword1" value={contactInfo.reemail} name="reemail" onChange={handleChange} required />
                </div> */}
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2 pr-lg-0">
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    pattern="[+0-9]{10,13}"
                    title="+910123456789 or 0123456789"
                    placeholder="Phone number *"
                    value={contactInfo.phone}
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2 pl-lg-2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="City *"
                    id="exampleInputPassword1"
                    value={contactInfo.city}
                    name="city"
                    onChange={handleChange}
                    required
                  />
                </div>

                {/* {RefundStatusCheck == false &&
                agent_bw_name == "Travel Agent Portal" ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-12 position-relative mb-3">
                    <div className="d-inline-flex">
                      <label className="custom-checkbox-1">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleOnChange}
                        />
                        <span></span>
                      </label>
                      <span
                        className="roboto-regular f-15 ml-2"
                        style={{ position: "relative" }}
                      >
                        I don't have a credit card
                      </span>
                    </div>
                  </div>
                ) : null} */}

                <div className="col-lg-12 col-md-12 col-sm-12 mb-2 ">
                  <p className="f-15">
                    We’ll send your confirmation to this email address.
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 justify-content-center">
                  <button type="submit" className="btn-primary-seach py-2">
                    {spinner ? (
                      <div
                        class="spinner-border spinner-border-sm text-light p-1 mr-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : null}
                    Confirm Booking
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer page_type="check-out" />
    </>
  );
}

export default CustomerInformation;
