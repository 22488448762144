import axios from "axios";

const API_BASE_URL = "https://roomph.ascendant.travel/tapdb_apis";
//    Fetching mart categories
// export async function getwhitelabels_api(req) {
//   try {
//     var url = "https://roomph.ascendant.travel/whitelabels_api/api/data";
//     // Define the headers for the request
//     const headers = {
//       "Content-Type": "application/json", // Example header
//     };

//     const response = await axios.post(
//       url,
//       JSON.stringify({
//         agent: req.agent,
//         password: req.password,
//       }),
//       { headers }
//     );
//     return response.data;
//   } catch (error) {
//     throw error;
//   }
// }

export async function authenticateUser(email, password) {
  try {
    var url = API_BASE_URL + "/agent_signin";
    const response = await axios.post(
      url,
      JSON.stringify({
        user_name: email,
        password: password,
      })
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw error;
  }
}

export async function updateAgent(password, id) {
  try {
    var url = "https://roomph.ascendant.travel/tap_apis/update_agent";
    const response = await axios.post(
      url,
      JSON.stringify({
        id: id,
        password: password,
      })
    );
    return response.data;
  } catch (error) {
    throw error;
  }
}

// to get tap accomodation
export async function get_tap_accomodation(chk_in, chk_out, id, agentid) {
  //console.log(chk_in, chk_out, id, agentid);
  try {
    // var url =
    //   "https://roomph.ascendant.travel/tapdb_apis/get_plans?chk_in=" +
    //   chk_in +
    //   "&chk_out=" +
    //   chk_out +
    //   "&accommodation_BE_id=" +
    //   id +
    //   "&agentid=" +
    //   agentid;
    var url =
    "https://roomph.ascendant.travel/tapdb_apis/get_plans?chk_in=" +
    chk_in +
    "&chk_out=" +
    chk_out +
    "&accommodation_BE_id=" +
    id +
    "&agentid=2";
    const response = await axios.get(url);
    console.log(response["data"]);
    if (response.data["data"] == "No Date Range Found") {
      response.data["data"] = null;
    }
    return response.data["data"];
  } catch (error) {
    throw error;
  }
}

// to get my hotels
export async function get_my_hotels(agentid) {
  //console.log(chk_in, chk_out, id, agentid);
  try {
    var url =
      "https://roomph.ascendant.travel/tapdb_apis/get_accomodation?agentId=" +
      agentid;
    const response = await axios.get(url);
    // console.log(response);

    return response.data["data"];
  } catch (error) {
    throw error;
  }
}
