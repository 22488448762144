import React from "react";
import ReactDOM from "react-dom";
import { hydrate, render } from "react-dom";

import App from "./App";
import "./fonts/Roboto-Black.ttf";
import { SessionProvider } from "./SessionProvider";

import * as serviceWorker from "./serviceWorker";

import "./index.css";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <SessionProvider>
      <App />
    </SessionProvider>,

    rootElement
  );
} else {
  render(
    <SessionProvider>
      <App />
    </SessionProvider>,
    rootElement
  );
}

serviceWorker.unregister();
