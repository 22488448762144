
export const credientials = {
  hostname: "localhost",
  // agent: "HBL",
  // userId: 10013,
  // password: "PLYGE8UY7P8KJ",
  // promotionID:6

  // agent: "pia",
  // userId: 10002,
  // password: "YGiDp9ex0022019",
  // promotionID: 5,

  // agent: "golootlo",
  // userId: 10029,
  // password: "KL98H6YGV2",
  // promotionID: 11,

  agent: "Travel Agent Portal",
  password: "KJH34H0D01",
  userId: 10008,
  promotionID: 4,
};

export const home_data_tap = {
  Agent: {
    RowCount: "1",
    Rows: [
      {
        AgentId: 4,
        AgentName: "Travel Agent Portal",
        agent_bw_id: 3,
        agent_bw_name: "Travel Agent Portal",
        agent_userId: 10008,
        agent_bw_password: "KJH34H0D01",
        temp_AgentName: "Jazz Mosafir",
        temp_agent_bw_id: "16",
        temp_agent_bw_name: "Jazz Mosafir",
        temp_agent_userid: "10016",
        temp_bw_password: "K0097AGTYW",
        description:
          "Book the best rates at\n1500 hotels in\n70+ Pakistani\nDestinations",
        logo_url: "../wl_assets/Logo/tap-logo.png",
        additional_logo_url: "../wl_assets/Logo/white-tap-logoo.png",
        favicon_url: "../wl_assets/favicon/tap-favicon.ico",
        primary_color: "#2A4476",
        secondary_color: "#EA504C",
        font_family: "roboto",
        font_color: "#000000",
        facebook_url:
          "https://www.facebook.com/hts.ascendant/about/?ref=page_internal",
        instagram_url: "https://www.instagram.com/ascendant.hts/",
        twitter_url: "#",
        linkedin_url:
          "https://www.linkedin.com/company/ascendant-travel/about/",
        contact: "+92-304-1111051",
        whatsapp_contact: "https://wa.link/eaobk2",
        CreateDate: "2021-06-09 11:48:38",
        LastModified: "2023-09-12 04:59:25",
      },
    ],
  },
  Banner: {
    RowCount: "1",
    Rows: [
      {
        Id: 9,
        agent_bw_name: "Travel Agent Portal",
        Type: "home banner",
        "offer-heading":
          "<b>TAP Offers! </b>Upto 50% off. <underline>Avail Now!</underline>",
        Image: "../wl_assets/banners/tap-web.jpg",
        mobile_image: "../wl_assets/banners/tap-web.jpg",
        ImageAlt: "Banner",
        Description:
          '<br><br><h3>Book the best rates At<br><b style="color:#EA5051;font-size:2rem">1500 </b> Hotels<br><b style="color:#EA5051;font-size:2rem" >70+</b>Pakistani Destinations</h3>',
        Status: "1",
        CreateDate: "2023-03-07 06:10:19",
        LastModified: "2023-09-13 09:00:13",
      },
    ],
  },

  "About Us": {
    RowCount: "1",
    Rows: [
      {
        agent_bw_name: "Travel Agent Portal",
        link: "https://ascendant.travel/about-us/",
      },
    ],
  },
  "Terms and Conditions": {
    RowCount: "1",
    Rows: [
      {
        agent_bw_name: "Travel Agent Portal",
        link: "https://ascendant.travel/",
      },
    ],
  },
  "Pop-Up Banner": {
    RowCount: "0",
    Rows: [],
  },
};
