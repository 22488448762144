import React, { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { FaDownload } from "react-icons/fa6";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { date_format } from "./BasicFunctionScript";
import { get_my_hotels } from "../../Server";
import { useSession } from "../../SessionProvider";
import BlankPage from "../BasicComponents/BlankPage";
import Footer from "../footer/Footer";
import history from "../history";
import Topbar from "../Topbar/Topbar";

export const Hotel_List = () => {
  const [hoteldetails, sethoteldetails] = useState("");
  const [data, setData] = useState("");
  const [city, seaarchCity] = useState("");
  const [uniqueCities, setuniqueCities] = useState([]);

  const [loading, setloading] = useState(false);
  const { user } = useSession();
  // console.log(user);

  const getData = async () => {
    if (user && user.id) {
      const data = await get_my_hotels(user.id);
      // console.log(data);
      if (data) {
        let cities = [...new Set(data.map((item) => item.city))];
        setuniqueCities(cities);
      }
      sethoteldetails(data);
      setData(data);
      setloading(true);
    } else {
      console.log("no users found");
      sethoteldetails("");
    }
  };

  useEffect(() => {
    getData();
  }, [user]);

  useEffect(() => {
    if (city !== "") {
      console.log(city);
      let filteredData = data.filter(
        (d) => d.city.toLowerCase() == city.toLowerCase()
      );
      sethoteldetails(filteredData);
    }
  }, [city]);

  return (
    <section>
      <Topbar color="#FF3D00" page="property" />
      <section className="container deals-cc">
        <div className="deals-container">
          <div className="main-heading text-center py-4">My Hotels</div>
        </div>
      </section>
      <div className="container mt-5 mb-5 past_bookings min-h-100">
        <div class=" mb-3">
          <button
            class="btn btn-primary dropdown-toggle"
            type="button"
            id="dropdownMenu2"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            {city ? city : "Search City"}
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
            {uniqueCities.map((c, i) => {
              return (
                <li key={i}>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() => seaarchCity(c)}
                  >
                    {c}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
        {loading ? (
          hoteldetails ? (
            <div className="grid_my_hotels">
              {hoteldetails.map((h, i) => {
                return (
                  <Link
                    to={`/hotels&city=${h.city}&check_in=${moment().format(
                      date_format
                    )}&check_out=${moment()
                      .add(1, "days")
                      .format(date_format)}&adult=2&rooms=1&nights=1&hotel_id=${
                      h.accommodation_BE_id
                    }&discount=null`}
                    key={i}
                  >
                    <div className="text-center">
                      <div className="grid_hotel_image">
                        <img src={h.accommodation_image} alt="" />
                      </div>
                      <div className="sub-heading mt-2 text text-black">
                        {h.accommodation_name}
                      </div>
                      <div className="line-height9 mt-1 text-black">{h.city}</div>
                    </div>
                  </Link>
                );
              })}
            </div>
          ) : (
            <BlankPage />
          )
        ) : (
          <div className="grid_my_hotels">
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
            <LoadingSkeleton />
          </div>
        )}
      </div>

      <Footer />
    </section>
  );
};
export const LoadingSkeleton = () => {
  return (
    <div>
      <div className="text-center">
        <div className="grid_hotel_image">
          <Skeleton width="100%" height={150} />
        </div>
        <div className="sub-heading mt-2">
          <Skeleton width={150} height={15} />
        </div>
        <div className="line-height9 mt-1">
          <Skeleton width={100} height={10} />
        </div>
      </div>
    </div>
  );
};

export default Hotel_List;
