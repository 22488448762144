import React, { useState } from "react";
import { RxCross1 } from "react-icons/rx";

import { updateAgent } from "../../Server";
import { useSession } from "../../SessionProvider";

import "./Password.css";

function UpdatePassword({ setIsModalOpen }) {
  const [password, setPassword] = useState("");
  const [ConfirmPass, setconfirmPass] = useState("");
  const { user } = useSession();

  const [ErrorMSg, SetErrorMSg] = useState("");
  const [SuccessMSg, setSuccessMSg] = useState("");

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (password == ConfirmPass) {
      const responce = await updateAgent(password, user.id);
      console.log(responce);
      if (responce["status"] == 200) {
        setSuccessMSg("Password Updated.");
      }
      setSuccessMSg("Password Updated.");
    } else {
      SetErrorMSg("Password does not matched.");
    }
  };
  return (
    <div className="main">
      {/* {console.log(popupPasswordBanner)} */}
      <div className="popupPassword position_relative">
        <div className="popupPassword-header">
          <span>Update Password</span>
          <RxCross1 onClick={() => setIsModalOpen(false)} />
        </div>

        <div className="popupPassword-body">
          <form onSubmit={handleSignIn}>
            {ErrorMSg && (
              <div class="alert alert-danger" role="alert">
                {ErrorMSg}
              </div>
            )}
            {SuccessMSg && (
              <div class="alert alert-info" role="alert">
                {SuccessMSg}
              </div>
            )}
            <div className="form-group">
              <input
                type="text"
                id="password"
                value={password}
                placeholder="New Password"
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="text"
                placeholder="Confirm Password"
                id="password"
                value={ConfirmPass}
                onChange={(e) => setconfirmPass(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="mt-3 btn-primary py-2 w-100">
              Update Password
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default UpdatePassword;
