import React, { Suspense, useEffect, useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Loading } from "react-loading-dot";
import { Router, Switch, Route } from "react-router-dom";

import {
  agentDetails,
  setTemplateTheme,
} from "./components/BasicComponents/BasicFunctionScript";
import ErrorBoundary from "./components/BasicComponents/ErrorBoundary";
import Hotel_List from "./components/BasicComponents/Hotel_List";
import { Booking } from "./components/Booking-Details/Booking";
import CustomerInformation from "./components/customerInformation/customerInformation";
import history from "./components/history";
import Maps from "./components/Maps/Maps";
import Thankyou from "./components/thankyou/thankyou";
import Cancellationbooking from "./Cancellationbooking";
import { credientials, home_data_tap } from "./credientials";
import ScrollToTop from "./ScrollToTop";

import "./App.css";
import "./Assests/falti-icons/flaticon.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const Home = React.lazy(() => import("./components/home/home"));
const Results = React.lazy(() =>
  import("./components/propertyListing/results/results")
);
const Description = React.lazy(() =>
  import("./components/propertyDetails/description/description")
);
const ErrorPage = React.lazy(() => import("./ErrorPage"));

function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTemplateTheme(home_data_tap, credientials).then(() => {
      setLoading(false);
    });
    // });
  }, []);

  return (
    <div className="App position-relative">
      <ErrorBoundary>
        <Router history={history}>
          <ScrollToTop />
          <Suspense
            fallback={<Loading dots={4} background="#7F7F7F" size="1.3rem" />}
          >
            {loading ? (
              <Loading dots={4} background="#7F7F7F" size="1.3rem" />
            ) : (
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/destination&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights">
                  <Results />
                </Route>
                <Route path="/information">
                  <CustomerInformation />
                </Route>
                <Route path="/hotels&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights&hotel_id=:id&discount=:discount">
                  <Description />
                </Route>

                <Route path="/cancellation-booking&accommodationid=:accommodationid&bookingid=:bookingid&pincode=:pincode&agent=:agent">
                  <Cancellationbooking />
                </Route>
                <Route
                  path="/map&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms"
                  component={Maps}
                />
                <Route path="/thankyou" component={Thankyou} />
                <Route path="/booking" component={Booking} />
                <Route path="/hotels_list" component={Hotel_List} />
                <Route path="*" component={() => <ErrorPage />} />
              </Switch>
            )}
          </Suspense>
        </Router>
      </ErrorBoundary>
    </div>
  );
}

export default App;
